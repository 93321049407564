import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */

/* @jsx mdx */

export const _frontmatter = {};
const layoutProps = {
  _frontmatter
};
const MDXLayout = "wrapper";
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">
    <h1>{`Automated Tasking with Selenium`}</h1>
    <h2>{`Classified Scripting and Testing  .`}</h2>
    <ul>
      <li parentName="ul">{`Objective : Automated Tasking .`}</li>
      <li parentName="ul">{`Method : Recursive and Iterating Function.`}</li>
    </ul>
    <hr></hr>
    <h2>{`What is Selenium ?`}</h2>
    <p>{`Selenium WebDriver is a collection of open source APIs which are used to automate the testing of a web application.
Their behaviour is on browser .
It supports many browsers such as Firefox, Chrome, IE, and Safari.`}</p>
    <h2>{`What we will test ?`}</h2>
    <p>{`Okay so let's get straightforward :`}</p>
    <ul>
      <li parentName="ul">{`an Automated test that will find the titles on Wiki`}</li>
      <li parentName="ul">{`Finding many title based on located element in wiki that will scroll over on pages`}</li>
    </ul>
    <h2>{`How it works ?`}</h2>
    <p>{`Selenium is an automated testing based on Webdriver it will crawl , event interaction , wait , etc to located the elements`}</p>
    <h2>{`Method :`}</h2>
    <ul>
      <li parentName="ul">{`Find the title using Xpath (element) `}</li>
      <li parentName="ul">{`Looping`}</li>
      <li parentName="ul">{`Print`}</li>
    </ul>
    <h1>{`Conclusion`}</h1>
    <p>{`Selenium is very fast for automated testing or multi tasking batch . But on the other side it has a limit , such as recaptcha or human auth.`}</p>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      